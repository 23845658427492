import {
  BasicPlanIcon,
  EverythingPlanIcon,
  GoldPlanIcon,
  GrowPlanIcon,
  OnDemandIcon,
  PlatinumIcon,
  ProPlanIcon,
  SilverPlanIcon,
  StartupIcon,
} from "../../assets/Icons/pricingIcons";
import {
  EmailIcon,
  FacebookIcon,
  InstaIcon,
  LinkedInIcon,
  PhoneIcon,
  WebsiteIcon,
  XIcon,
  YoutubeIcon,
} from "../../assets/Icons/profileIcon";
// import ProfileImgDodi from "../../assets/images/Profile/ProfileD.png";
// import ProfileImgEsraa from "../../assets/images/Profile/ProfileE.png";
// import ScannerE from "../../assets/images/Profile/ScannerE.png";
// import ScannerESM from "../../assets/images/Profile/ScannerESM.png";

// import ScannerDSM from "../../assets/images/Profile/ScannerDSM.png";
// import ScannerD from "../../assets/images/Profile/ScannerD.png";
import DodiVcfFile from "../../assets/files/Dodi Kawouk.vcf";
import EsraaVcfFile from "../../assets/files/Esraa Mahmoud.vcf";

export const pricingPlansM = [
  {
    icon: <OnDemandIcon />,
    planName: "On Demand",
    price: "4¢",
    priceUnit: "per minute/text",
    timeValue: "no monthly fee",
    emailValue: "1K",
    perMinTextValue: "4.0¢",
    perNumberValue: "$5",
    isPopular: false,
  },
  {
    icon: <BasicPlanIcon />,
    planName: "Basic",
    price: "$99",
    priceUnit: "/month",
    timeValue: "2.8K",
    emailValue: "3K",
    perMinTextValue: "3.5¢",
    perNumberValue: "$4",
    isPopular: false,
  },
  {
    icon: <StartupIcon />,
    planName: "Startup",
    price: "$299",
    priceUnit: "/month",
    timeValue: "10K",
    emailValue: "6K",
    perMinTextValue: "3.0¢",
    perNumberValue: "$3",
    isPopular: false,
  },
  {
    icon: <GrowPlanIcon />,
    planName: "Grow",
    price: "$899",
    priceUnit: "/month",
    timeValue: "36K",
    emailValue: "10K",
    perMinTextValue: "2.5¢",
    perNumberValue: "$2",
    isPopular: true,
  },
  {
    icon: <ProPlanIcon />,
    planName: "Pro",
    price: "$1,499",
    priceUnit: "/month",
    timeValue: "75K",
    emailValue: "20K",
    perMinTextValue: "2.0¢",
    perNumberValue: "$1",
    isPopular: false,
  },
];

export const HighVolumePlansM = [
  {
    icon: <SilverPlanIcon />,
    planName: "Silver",
    price: "$2,500",
    priceUnit: "/month",
    timeValue: "156K",
    emailValue: "50K",
    perMinTextValue: "1.6¢",
    perNumberValue: "$0.8",
    isPopular: false,
  },
  {
    icon: <GoldPlanIcon />,
    planName: "Gold",
    price: "$6,000",
    priceUnit: "/month",
    timeValue: "500K",
    emailValue: "150K",
    perMinTextValue: "1.2¢",
    perNumberValue: "$0.7",
    isPopular: true,
  },
  {
    icon: <PlatinumIcon />,
    planName: "Platinum",
    price: "$12,000",
    priceUnit: "/month",
    timeValue: "1.2M",
    emailValue: "400K",
    perMinTextValue: "1.0¢",
    perNumberValue: "$0.6",
    isPopular: false,
  },
  {
    icon: <EverythingPlanIcon />,
    planName: "Everything",
    price: "$20,000",
    priceUnit: "/month",
    timeValue: "4M",
    emailValue: "1M",
    perMinTextValue: "0.5¢",
    perNumberValue: "$0.5",
    isPopular: false,
  },
];

export const industryList = [
  "Accounting",
  "Airlines/Aviation",
  "Alternative Dispute Resolution",
  "Alternative Medicine",
  "Animation",
  "Apparel & Fashion",
  "Architecture & Planning",
  "Arts and Crafts",
  "Automotive",
  "Aviation & Aerospace",
  "Banking",
  "Biotechnology",
  "Broadcast Media",
  "Building Materials",
  "Business Supplies and Equipment",
  "Capital Markets",
  "Chemicals",
  "Civic & Social Organization",
  "Civil Engineering",
  "Commercial Real Estate",
  "Computer & Network Security",
  "Computer Games",
  "Computer Hardware",
  "Computer Networking",
  "Computer Software",
  "Internet",
  "Construction",
  "Consumer Electronics",
  "Consumer Goods",
  "Consumer Services",
  "Cosmetics",
  "Dairy",
  "Defense & Space",
  "Design",
  "Education Management",
  "E-Learning",
  "Electrical/Electronic Manufacturing",
  "Entertainment",
  "Environmental Services",
  "Events Services",
  "Executive Office",
  "Facilities Services",
  "Farming",
  "Financial Services",
  "Fine Art",
  "Fishery",
  "Food & Beverages",
  "Food Production",
  "Fund-Raising",
  "Furniture",
  "Gambling & Casinos",
  "Glass, Ceramics & Concrete",
  "Government Administration",
  "Government Relations",
  "Graphic Design",
  "Health, Wellness and Fitness",
  "Higher Education",
  "Hospital & Health Care",
  "Hospitality",
  "Human Resources",
  "Import and Export",
  "Individual & Family Services",
  "Industrial Automation",
  "Information Services",
  "Information Technology and Services",
  "Insurance",
  "International Affairs",
  "International Trade and Development",
  "Investment Banking",
  "Investment Management",
  "Judiciary",
  "Law Enforcement",
  "Law Practice",
  "Legal Services",
  "Legislative Office",
  "Leisure, Travel & Tourism",
  "Libraries",
  "Logistics and Supply Chain",
  "Luxury Goods & Jewelry",
  "Machinery",
  "Management Consulting",
  "Maritime",
  "Market Research",
  "Marketing and Advertising",
  "Mechanical or Industrial Engineering",
  "Media Production",
  "Medical Devices",
  "Medical Practice",
  "Mental Health Care",
  "Military",
  "Mining & Metals",
  "Motion Pictures and Film",
  "Museums and Institutions",
  "Music",
  "Nanotechnology",
  "Newspapers",
  "Nonprofit Organization Management",
  "Oil & Energy",
  "Online Media",
  "Outsourcing/Offshoring",
  "Package/Freight Delivery",
  "Packaging and Containers",
  "Paper & Forest Products",
  "Performing Arts",
  "Pharmaceuticals",
  "Philanthropy",
  "Photography",
  "Plastics",
  "Political Organization",
  "Primary/Secondary Education",
  "Printing",
  "Professional Training & Coaching",
  "Program Development",
  "Public Policy",
  "Public Relations and Communications",
  "Public Safety",
  "Publishing",
  "Railroad Manufacture",
  "Ranching",
  "Real Estate",
  "Recreational Facilities and Services",
  "Religious Institutions",
  "Renewables & Environment",
  "Research",
  "Restaurants",
  "Retail",
  "Security and Investigations",
  "Semiconductors",
  "Shipbuilding",
  "Sporting Goods",
  "Sports",
  "Staffing and Recruiting",
  "Supermarkets",
  "Telecommunications",
  "Textiles",
  "Think Tanks",
  "Tobacco",
  "Translation and Localization",
  "Transportation/Trucking/Railroad",
  "Utilities",
  "Venture Capital & Private Equity",
  "Veterinary",
  "Warehousing",
  "Wholesale",
  "Wine and Spirits",
  "Wireless",
  "Writing and Editing",
  "Software Development",
  "Collections",
  "Legal",
  "Education",
  "Finance",
  "Credit Card Collections",
  "Computer Services",
  "Contact Center Solutions",
  "Marketing",
  "Office and Administrative Services",
];

export const profileData = [
  {
    id: 1,
    name: "Esraa Mahmoud",
    position: "Chief Operating Officer",
    email: 'esraa@avnovo.com',
    image: 'https://images.dcdial.com/avnovo.com/profile_E.png',
    // scannerImgRes: ScannerESM,
    scannerImg: "https://images.dcdial.com/avnovo.com/ScannerE.png",
    location: 'Abu Dhabi, UAE',
    phone: '+971 56 507 1521',
    file: EsraaVcfFile,
    socialLink : 'https://www.linkedin.com/in/esraa-mahmoud-73b03b26/',
    url: "https://www.avnovo.com/team/esraamahmoud&token=f59588cfdf55383062c76c6966c03430",
    socialMedia: [
      {
        id: 1,
        icon: <EmailIcon />,
        name: "Email",
        value: "esraa@avnovo.com",
        url: "mailto:esraa@avnovo.com",
      },
      {
        id: 2,
        icon: <PhoneIcon />,
        name: "Phone number",
        value: "+971 56 507 1521",
        url: "tel:+971 56 507 1521",
      },
      {
        id: 3,
        icon: <WebsiteIcon />,
        name: "Website",
        value: "avnovo.com",
        url: "https://avnovo.com/",
      },
      {
        id: 4,
        icon: <LinkedInIcon />,
        name: "LinkedIn",
        value: "linkedin.com/in/esraa-mahmoud",
        url: "https://www.linkedin.com/in/esraa-mahmoud-73b03b26/",
      },
      {
        id: 5,
        icon: <XIcon />,
        name: "X",
        value: "@avnovoapp",
        url: "https://twitter.com/avnovoapp",
      },
      {
        id: 6,
        icon: <InstaIcon />,
        name: "Instagram",
        value: "@avnovoapp",
        url: "https://www.instagram.com/avnovoapp/",
      },
      {
        id: 7,
        icon: <FacebookIcon />,
        name: "Facebook",
        value: "facebook.com/avnovo",
        url: "https://www.facebook.com/avnovo",
      },
      {
        id: 8,
        icon: <YoutubeIcon />,
        name: "YouTube",
        value: "@avnovo",
        url: "https://www.youtube.com/@avnovo",
      },
    ],
  },
  {
    id: 0,
    name: "Dodi Kawouk",
    position: "Founder & CEO",
    email: 'dodi@avnovo.com',
    image: 'https://images.dcdial.com/avnovo.com/profile_D.png',
    scannerImg: "https://images.dcdial.com/avnovo.com/ScannerD.png",
    // scannerImgRes: ScannerDSM,
    location: 'Abu Dhabi, UAE',
    phone: '+1 (404) 200-2322',
    socialLink: 'http://linkedin.com/in/dodikawouk',
    url: "https://www.avnovo.com/team/dodikawouk&token=467b3097d8617cd659954a0ccbcb5314",
    file: DodiVcfFile,
    socialMedia: [
      {
        id: 1,
        icon: <EmailIcon />,
        name: "Email",
        value: "dodi@avnovo.com",
        url: "mailto:dodi@avnovo.com",
      },
      {
        id: 2,
        icon: <PhoneIcon />,
        name: "Phone number",
        value: "+1 (404) 200-2322",
        url: "tel:+1 (404) 200-2322",
      },
      {
        id: 3,
        icon: <WebsiteIcon />,
        name: "Website",
        value: "avnovo.com",
        url: "https://avnovo.com/",
      },
      {
        id: 4,
        icon: <LinkedInIcon />,
        name: "LinkedIn",
        value: "linkedin.com/in/dodikawouk",
        url: "http://linkedin.com/in/dodikawouk",
      },
      {
        id: 5,
        icon: <XIcon />,
        name: "X",
        value: "@avnovoapp",
        url: "https://twitter.com/avnovoapp",
      },
      {
        id: 6,
        icon: <InstaIcon />,
        name: "Instagram",
        value: "@avnovoapp",
        url: "https://www.instagram.com/avnovoapp/",
      },
      {
        id: 7,
        icon: <FacebookIcon />,
        name: "Facebook",
        value: "facebook.com/avnovo",
        url: "https://www.facebook.com/avnovo",
      },
      {
        id: 8,
        icon: <YoutubeIcon />,
        name: "YouTube",
        value: "@avnovo",
        url: "https://www.youtube.com/@avnovo",
      },
    ],
  },
];

export  const toasterObj = {
  position: "top-center",
  autoClose: 2000,
  hideProgressBar: true,
  closeOnClick: false,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};
