import React from "react";
import ArrowImage from "../../assets/images/Footer/arrow-right.png";
import { useWindowSize } from "../../utils/Hooks/useWindowSize";

const SocialCard = ({ media }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth < 500;
  return (
    <a href={media.url} target={media.name === "Email" || media.name === "Phone number"? "" : "_blank"}>
      <div className="p-3 rounded-[20px] bg-ButtonPHover flex justify-between w-[580px] max_xl:w-[460px] items-center cursor-pointer max_md:w-full">
        <div className="flex gap-3 max_sm:gap-2">
          <div className="rounded-full w-[70px] h-[70px] max_sm:h-[50px] max_sm:w-[50px] bg-ButtonP flex justify-center items-center">
            {React.cloneElement(media.icon, {
              style: {
                width: isMobileView ? 26 : 40,
                height: isMobileView ? 26 : 40,
              },
            })}
          </div>
          <div>
            <p className="text-textMedium font-medium text-white max_sm:text-textNormal">
              {media.name}
            </p>
            <p className="mt-1 text-textSmall font-normal text-white max_sm:text-textExtraSmall">
              {media.value}
            </p>
          </div>
        </div>
        <div>
          <img src={ArrowImage} alt="Arrow" />
        </div>
      </div>
    </a>
  );
};

export default SocialCard;
