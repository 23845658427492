import React, { useEffect, useRef, useState } from 'react'
import Navbar from '../Navbar/Navbar'
import { CSSTransition } from 'react-transition-group'
import MobileMenu from '../../utils/MobileMenu'
import { useLocation } from 'react-router-dom'

const HeaderForProfile = ({children}) => {
  const location = useLocation();
  const segments = location?.pathname?.split("/team/")[1]?.split("&token=");
  const nodeRef = useRef(null);
  const outsideRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [navbarChangeColor, setNavbarChangeColor] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  useEffect(() => {
    if (segments) {
      if (
        (segments[0] === "dodikawouk" &&
          segments[1] === "467b3097d8617cd659954a0ccbcb5314") ||
        (segments[0] === "esraamahmoud" &&
          segments[1] === "f59588cfdf55383062c76c6966c03430")
      ) {
        setIsVerified(true);
      }
    }
  }, [segments]);

  function useScrollDirection() {
    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {
      let lastScrollY = window.pageYOffset;

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        const direction = scrollY > lastScrollY ? "down" : "up";
        if (
          direction !== scrollDirection &&
          (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
        ) {
          setScrollDirection(direction);
        }
        lastScrollY = scrollY > 0 ? scrollY : 0;
      };
      window.addEventListener("scroll", updateScrollDirection); // add event listener
      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      };
    }, [scrollDirection]);

    return scrollDirection;
  }
  const scrollDirection = useScrollDirection();
  return (
    <div className="relative w-full">
        <div
          className={`sticky z-40 ${
            scrollDirection === "down" ? "-top-24" : "top-0"
          } transition-all duration-500`}
        >
          <div
            className={`relative m-auto w-full bg-PrimaryT bg-transparent`}
          >
            <Navbar
              setIsMobileMenuOpen={setIsMobileMenuOpen}
              navbarChangeColor={navbarChangeColor}
              // isImageLoaded={isImageLoaded}
              isVerified={isVerified}
            />

            <CSSTransition
              in={isMobileMenuOpen}
              nodeRef={nodeRef}
              timeout={300}
              classNames="menu"
              type={"out-in" | "in-out"}
              unmountOnExit
              onEnter={() => setIsMobileMenuOpen(true)}
              onExited={() => setIsMobileMenuOpen(false)}
            >
              <MobileMenu
                nodeRef={nodeRef}
                outsideRef={outsideRef}
                setIsMobileMenuOpen={setIsMobileMenuOpen}
              />
            </CSSTransition>
          </div>
        </div>
        <div>{children}</div>
      </div>
  )
}

export default HeaderForProfile
