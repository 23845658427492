import React, { useState } from "react";
import LogoEn from "../../assets/images/Footer/Logo.svg";
import CloseIcon from "../../assets/images/Navbar/CloseIcon.svg";
import { InputField } from "../Common/InputFields";
import SelectPhoneNew from "../Common/SelectPhoneNew";
import { ButtonFilled } from "../../utils/Button";
import { useWindowSize } from "../../utils/Hooks/useWindowSize";
import axios from "axios";
import { toast } from "react-toastify";
import { CopiedIcon2 } from "../../assets/Icons/profileIcon";
import { PhoneNumberUtil } from "google-libphonenumber";
import { toasterObj } from "../Pricing/jsonData";

const ExchangeForm = ({ setIsModalExcOpen, userCountry, selectedProfile }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 500;
  const initialData = {
    name: "",
    email: "",
    mobile_no: "",
    note: "",
    company:"",
  };
  const [userDetails, setUserDetails] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const initialPhoneInput = {
    country_code: "",
    phone_number: "",
  };
  const [inputMobileData, setInputMobileData] = useState(initialPhoneInput);
  const [errors, setErrors] = useState({
    name: "",
    email: "",
    mobile_no: "",
    note: "",
    company: "",
  });
  // const [currentDate, setCurrentDate] = useState("");
  // const [currentTime, setCurrentTime] = useState("");

  const getCurrentDateTime = () => {
    const date = new Date();
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const month = monthNames[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();
    // setCurrentDate(`${month} ${day}, ${year}`);

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // The hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    // setCurrentTime(`${hours}:${minutes} ${ampm}`);
    return {
      currentDate: `${month} ${day}, ${year}`,
      currentTime: `${hours}:${minutes} ${ampm}`,
    };
  };

  const handleOnChange = (e, name) => {
    setUserDetails({
      ...userDetails,
      [name]: e.target.value, // Update the specific field based on fieldName
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: false,
    }));
  };
  const getInitials = (first_name, last_name) => {
    const firstInitial = first_name ? first_name[0].toUpperCase() : "";
    const lastInitial = last_name ? last_name[0].toUpperCase() : "";
    return lastInitial ? `${firstInitial}${lastInitial}` : firstInitial;
  };

  const getFirstAndLastName = (name) => {
    const nameParts = name.split(" ");
    const firstName = nameParts[0];
    const lastName =
      nameParts.length > 1 ? nameParts[nameParts.length - 1] : "";
    return { firstName, lastName };
  };
  const phoneUtil = PhoneNumberUtil.getInstance();
  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const { firstName: p1_first_name, lastName: p1_last_name } =
    getFirstAndLastName(selectedProfile.name);
  const validateForm = () => {
    const newErrors = {};
    const isValidNum = isPhoneValid(inputMobileData?.phone_number);
    // Check for non-empty fields
    newErrors.name = !userDetails.name;
    newErrors.email = !userDetails.email;
    newErrors.mobile_no = !inputMobileData.phone_number;
    if (!newErrors.mobile_no) {
      newErrors.mobile_no = !isValidNum;
    }
    if (!newErrors.name) {
      newErrors.name = userDetails.name.length < 3;
    }
    if (!newErrors.email) {
      newErrors.email = !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userDetails.email);
    }

    return newErrors;
  };

  const handleSubmit = () => {
    const formErrors = validateForm();
    setErrors(formErrors);
    const hasErrors = Object.values(formErrors).some((error) => error);

    if (!hasErrors) {
      const { currentDate, currentTime } = getCurrentDateTime();
      setIsLoading(true);
      const { firstName: p2_first_name, lastName: p2_last_name } =
        getFirstAndLastName(userDetails.name);

      const data = {
        type: "send_general_email",
        email_type: "avnovo_contact_info",
        P1_TITLE: selectedProfile.position,
        P1_FIRST_NAME: p1_first_name,
        P1_LAST_NAME: p1_last_name,
        P1_PHONE_NUMBER: selectedProfile.phone,
        P1_NOTES: "",
        P1_LINKEDIN_URL: selectedProfile.socialLink,
        // P1_LOCATION: selectedProfile.location,
        P1_IMAGE_URL: selectedProfile.image,
        P1_EMAIL_ADDRESS: selectedProfile.email,
        P2_EMAIL_ADDRESS: userDetails.email,
        P2_FIRST_NAME: p2_first_name,
        P2_LAST_NAME: p2_last_name,
        P2_PHONE_NUMBER: inputMobileData.phone_number,
        P2_NOTES: userDetails.note,
        P2_INITIALS: getInitials(p2_first_name, p2_last_name),
        LOCATION: userCountry.region ? `${userCountry?.region}, ${userCountry?.countryName}` : userCountry?.countryName,
        DATE: currentDate,
        TIME: currentTime,
        P2_COMPANY: userDetails.company ? userDetails.company : "",
        api_key: "71a629e3685846a9173884a03f8f559a4d3c6510",
      };            
      axios
        .post("https://api.dcdial.com/api/", data, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            setIsModalExcOpen(false);
            setUserDetails(initialData);
            setInputMobileData(initialPhoneInput);
            toast(<CustomeMsg name={p1_first_name} />, toasterObj);
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center min-h-[30px] max_sm:min-h-5">
        <div className="w-[110px]">
          <img src={LogoEn} alt="Logo" className="w-full" />
        </div>
        <div
          className="cursor-pointer"
          onClick={() => setIsModalExcOpen(false)}
        >
          <img src={CloseIcon} alt="Close" />
        </div>
      </div>
      <div className="mt-4">
        <p className="text-textSmall font-normal text-TertiaryT max_sm:text-textExtraSmall">
          Share your contact information.
          <br />
          You will get {p1_first_name}’s contact in a follow-up email.
        </p>
        <div className="flex flex-col gap-5 mt-[30px]">
          <InputField
            type="text"
            value={userDetails.name}
            placeholder="Name"
            name="name"
            handleOnChange={handleOnChange}
            error={errors.name}
          />

          <SelectPhoneNew
            inputMobileData={inputMobileData}
            setInputValue={setInputMobileData}
            setErrors={setErrors}
            errors={errors}
            detectedCountry={
              userCountry.country_code && userCountry.country_code
            }
          />

          <InputField
            type="text"
            value={userDetails.email}
            placeholder="Email address*"
            name="email"
            handleOnChange={handleOnChange}
            error={errors.email}
          />
          <InputField
            type="text"
            value={userDetails.company}
            placeholder="Company"
            name="company"
            handleOnChange={handleOnChange}
            error={errors.company}
          />
          <InputField
            type="text"
            value={userDetails.note}
            placeholder="Leave your meeting note..."
            name="note"
            handleOnChange={handleOnChange}
            error={errors.note}
          />
          <div className="mt-[10px]">
            <ButtonFilled
              isLoading={isLoading}
              title="Exchange"
              rounded="8px"
              height={isMobileView ? "40px" : "46px"}
              onClick={() => {
                if (!isLoading) {
                  handleSubmit();
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeForm;

export const CustomeMsg = ({ name }) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 720;
  return (
    <div className="w-max flex gap-2 max_800:max-w-[500px] max_sm:max-w-[300px] max_sm_380:max-w-[270px]">
      <div className="max_800:mt-[2px]">
        <CopiedIcon2
          style={{
            width: isMobileView ? "18" : "24",
            height: isMobileView ? "18" : "24",
          }}
        />
      </div>
      <p className="text-textSmall font-normal text-[#23B339] font-OutfitFont max_sm:text-textExtraSmall">
        <span className="font-medium">Success!</span>
        <br />
        Your contact was sent to {name}
      </p>
    </div>
  );
};
