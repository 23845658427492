import React from "react";
import CloseIcon from "../../assets/images/Navbar/CloseIcon.svg";

const ScannerComponent = ({ setIsModalOpen,scannerImg }) => {

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-subHeadingW font-semibold max_sm:text-[28px]">
          Scan QR
        </p>
        <div className="cursor-pointer" onClick={() => setIsModalOpen(false)}>
          <img src={CloseIcon} alt="Close" />
        </div>
      </div>
      <div className="p-10 max_sm:p-8 flex justify-center">
        <img src={scannerImg} alt="Scanner" />
      </div>
    </div>
  );
};

export default ScannerComponent;
