import React, { useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import i18n from "./i18n";
import PricingPage from "./Pages/PricingPage";
import ProductPage from "./Pages/ProductPage";
import SolutionPage from "./Pages/SolutionPage";
import PageNotFound from "./Pages/PageNotFound";
import CommonLayout from "./utils/CommonLayout";
import GetInTouch from "./Pages/GetInTouch";
import AboutUsPage from "./Pages/AboutUsPage";
import LegalPage from "./Pages/LegalPage";
import TermesOfService from "./Pages/Legal/TermesOfService";
import SpecificProductService from "./Pages/Legal/SpecificProductService";
import DataProcessing from "./Pages/Legal/DataProcessing";
import AcceptableUsePolicy from "./Pages/Legal/AcceptableUsePolicy";
import DeveloperPolicy from "./Pages/Legal/DeveloperPolicy";
import DeveloperTerms from "./Pages/Legal/DeveloperTerms";
import WebTermsOfUse from "./Pages/Legal/WebTermsOfUse";
import WebAccessibility from "./Pages/Legal/WebAccessibility";
import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
import CookiePolicy from "./Pages/Legal/CookiePolicy";
import DigitalServicePolicy from "./Pages/Legal/DigitalServicePolicy";
import SecurityPage from "./Pages/SecurityPage";
import CRMPage from "./Pages/CRMPage";
import AIAssitantPage from "./Pages/AIAssitantPage";
import PaymentPage from "./Pages/PaymentPage";
import CommunicationPage from "./Pages/CommunicationPage";
import IntegrationAndPaymentPage from "./Pages/IntegrationAndPaymentPage";
import FeaturePage from "./Pages/FeaturePage";
import FAQPage from "./Pages/FAQPage";
// import RegisterPage from "./Pages/Auth/RegisterPage";
import SignInPage from "./Pages/Auth/SignInPage";
import ProfilePage from "./Pages/Profile/ProfilePage";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const location = useLocation();
  const [isImageLoaded, setImageLoaded] = useState(false);
  return (
    <div lang="en" dir="ltr" id="main-app" className="bg-WebRootColor">
      <Routes>
        <Route path="/team/:profile" element={<ProfilePage />} />
        {location.pathname.includes("/teams") ? (
          <>
            <Route path="/team/:profile" element={<ProfilePage />} />
          </>
        ) : (
          <>
            <Route element={<CommonLayout isImageLoaded={isImageLoaded} />}>
              <Route
                path="/"
                element={
                  <HomePage
                    isImageLoaded={isImageLoaded}
                    setImageLoaded={setImageLoaded}
                  />
                }
              />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/features" element={<FeaturePage />} />
              <Route path="/faq" element={<FAQPage />} />
              <Route path="/products" element={<ProductPage />} />
              <Route path="/products/crm" element={<CRMPage />} />
              <Route
                path="/products/ai-assistant"
                element={<AIAssitantPage />}
              />
              <Route path="/products/payments" element={<PaymentPage />} />
              <Route
                path="/products/communication"
                element={<CommunicationPage />}
              />
              <Route
                path="/apps-payments"
                element={<IntegrationAndPaymentPage />}
              />
              <Route path="/solutions" element={<SolutionPage />} />
              <Route path="/security" element={<SecurityPage />} />
              <Route path="/contact/get-in-touch" element={<GetInTouch />} />
              <Route path="/about-us" element={<AboutUsPage />} />
              <Route path="/legal" element={<LegalPage />} />
              <Route
                path="/legal/terms-of-service"
                element={<TermesOfService />}
              />
              <Route
                path="/legal/product-specific-terms"
                element={<SpecificProductService />}
              />
              <Route path="/legal/dpa" element={<DataProcessing />} />
              <Route
                path="/legal/acceptable-use"
                element={<AcceptableUsePolicy />}
              />
              <Route
                path="/legal/developer-policy"
                element={<DeveloperPolicy />}
              />
              <Route
                path="/legal/developer-terms"
                element={<DeveloperTerms />}
              />
              <Route
                path="/legal/website-terms-of-use"
                element={<WebTermsOfUse />}
              />
              <Route
                path="/legal/website-accessibility"
                element={<WebAccessibility />}
              />
              <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/legal/cookie-policy" element={<CookiePolicy />} />
              <Route
                path="/legal/digital-services-act"
                element={<DigitalServicePolicy />}
              />
            </Route>
            <Route path="/sign-in" element={<SignInPage />} />
          </>
        )}

        <Route path="*" element={<PageNotFound />} />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
