import React, { useEffect } from "react";

const ProfileModal = ({ isOpen, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [isOpen]);
  if (!isOpen) return null;
  return (
    <>
      <div
        className={`fixed top-0 right-0 left-0 z-40 w-full bg-white opacity-30 h-full`}
      ></div>

      {/* Modal content */}
      <div
        className={`fixed right-0 left-0 z-50 w-full  flex justify-center top-0 items-center h-screen`}
      >
        <div className={`relative w-full max-w-[590px] max_sm:px-5`}>
          <div
            className={`relative p-[30px] max_md:p-5 max_sm:p-5 rounded-[10px] bg-white`}
            style={{ boxShadow: "0px 4px 130px 0px #3C425733" }}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileModal;
