import React, { Suspense, useMemo, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// import HomePage from "./Pages/HomePage";
import i18n from "./i18n";
// import PricingPage from "./Pages/PricingPage";
// import ProductPage from "./Pages/ProductPage";
// import SolutionPage from "./Pages/SolutionPage";
// import PageNotFound from "./Pages/PageNotFound";
// import CommonLayout from "./utils/CommonLayout";
// import GetInTouch from "./Pages/GetInTouch";
// import AboutUsPage from "./Pages/AboutUsPage";
// import LegalPage from "./Pages/LegalPage";
// import TermesOfService from "./Pages/Legal/TermesOfService";
// import SpecificProductService from "./Pages/Legal/SpecificProductService";
// import DataProcessing from "./Pages/Legal/DataProcessing";
// import AcceptableUsePolicy from "./Pages/Legal/AcceptableUsePolicy";
// import DeveloperPolicy from "./Pages/Legal/DeveloperPolicy";
// import DeveloperTerms from "./Pages/Legal/DeveloperTerms";
// import WebTermsOfUse from "./Pages/Legal/WebTermsOfUse";
// import WebAccessibility from "./Pages/Legal/WebAccessibility";
// import PrivacyPolicy from "./Pages/Legal/PrivacyPolicy";
// import CookiePolicy from "./Pages/Legal/CookiePolicy";
// import DigitalServicePolicy from "./Pages/Legal/DigitalServicePolicy";
// import SecurityPage from "./Pages/SecurityPage";
// import CRMPage from "./Pages/CRMPage";
// import AIAssitantPage from "./Pages/AIAssitantPage";
// import PaymentPage from "./Pages/PaymentPage";
// import CommunicationPage from "./Pages/CommunicationPage";
// import IntegrationAndPaymentPage from "./Pages/IntegrationAndPaymentPage";
// import FeaturePage from "./Pages/FeaturePage";
// import FAQPage from "./Pages/FAQPage";
// import RegisterPage from "./Pages/Auth/RegisterPage";
// // import SignInPage from "./Pages/Auth/SignInPage";
// import ProfilePage from "./Pages/Profile/ProfilePage";
// import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.css";

const HomePage = React.lazy(() => import("./Pages/HomePage"));
const PricingPage = React.lazy(() => import("./Pages/PricingPage"));
const ProductPage = React.lazy(() => import("./Pages/ProductPage"));
const SolutionPage = React.lazy(() => import("./Pages/SolutionPage"));
const PageNotFound = React.lazy(() => import("./Pages/PageNotFound"));
const CommonLayout = React.lazy(() => import("./utils/CommonLayout"));
const GetInTouch = React.lazy(() => import("./Pages/GetInTouch"));
const AboutUsPage = React.lazy(() => import("./Pages/AboutUsPage"));
const LegalPage = React.lazy(() => import("./Pages/LegalPage"));
const TermesOfService = React.lazy(() =>
  import("./Pages/Legal/TermesOfService")
);
const SpecificProductService = React.lazy(() =>
  import("./Pages/Legal/SpecificProductService")
);
const DataProcessing = React.lazy(() => import("./Pages/Legal/DataProcessing"));
const AcceptableUsePolicy = React.lazy(() =>
  import("./Pages/Legal/AcceptableUsePolicy")
);
const DeveloperPolicy = React.lazy(() =>
  import("./Pages/Legal/DeveloperPolicy")
);
const DeveloperTerms = React.lazy(() => import("./Pages/Legal/DeveloperTerms"));
const WebTermsOfUse = React.lazy(() => import("./Pages/Legal/WebTermsOfUse"));
const WebAccessibility = React.lazy(() =>
  import("./Pages/Legal/WebAccessibility")
);
const PrivacyPolicy = React.lazy(() => import("./Pages/Legal/PrivacyPolicy"));
const CookiePolicy = React.lazy(() => import("./Pages/Legal/CookiePolicy"));
const DigitalServicePolicy = React.lazy(() =>
  import("./Pages/Legal/DigitalServicePolicy")
);
const SecurityPage = React.lazy(() => import("./Pages/SecurityPage"));
const CRMPage = React.lazy(() => import("./Pages/CRMPage"));
const AIAssitantPage = React.lazy(() => import("./Pages/AIAssitantPage"));
const PaymentPage = React.lazy(() => import("./Pages/PaymentPage"));
const CommunicationPage = React.lazy(() => import("./Pages/CommunicationPage"));
const IntegrationAndPaymentPage = React.lazy(() =>
  import("./Pages/IntegrationAndPaymentPage")
);
const FeaturePage = React.lazy(() => import("./Pages/FeaturePage"));
const FAQPage = React.lazy(() => import("./Pages/FAQPage"));
const SignInPage = React.lazy(() => import("./Pages/Auth/SignInPage"));
const ProfilePage = React.lazy(() => import("./Pages/Profile/ProfilePage"));

function App() {
  const location = useLocation();
  const [isImageLoaded, setImageLoaded] = useState(false);

  useMemo(() => {
    console.log("09/12");
  }, []);

  return (
    <div lang="en" dir="ltr" id="main-app" className="bg-WebRootColor">
      <Routes>
        <Route
          path="/team/:profile"
          element={
            <Suspense fallback={<></>}>
              <ProfilePage />
            </Suspense>
          }
        />
        {location.pathname.includes("/teams") ? (
          <>
            <Route
              path="/team/:profile"
              element={
                <Suspense fallback={<></>}>
                  <ProfilePage />
                </Suspense>
              }
            />
          </>
        ) : (
          <>
            <Route
              element={
                <Suspense fallback={<></>}>
                  <CommonLayout isImageLoaded={isImageLoaded} />
                </Suspense>
              }
            >
              <Route
                path="/"
                element={
                  <Suspense fallback={<></>}>
                    <HomePage
                      isImageLoaded={isImageLoaded}
                      setImageLoaded={setImageLoaded}
                    />
                  </Suspense>
                }
              />
              <Route
                path="/pricing"
                element={
                  <Suspense fallback={<></>}>
                    <PricingPage />
                  </Suspense>
                }
              />
              <Route
                path="/features"
                element={
                  <Suspense fallback={<></>}>
                    <FeaturePage />
                  </Suspense>
                }
              />
              <Route
                path="/faq"
                element={
                  <Suspense fallback={<></>}>
                    <FAQPage />
                  </Suspense>
                }
              />
              <Route
                path="/products"
                element={
                  <Suspense fallback={<></>}>
                    <ProductPage />
                  </Suspense>
                }
              />
              <Route
                path="/products/crm"
                element={
                  <Suspense fallback={<></>}>
                    <CRMPage />
                  </Suspense>
                }
              />
              <Route
                path="/products/ai-assistant"
                element={
                  <Suspense fallback={<></>}>
                    <AIAssitantPage />
                  </Suspense>
                }
              />
              <Route
                path="/products/payments"
                element={
                  <Suspense fallback={<></>}>
                    <PaymentPage />
                  </Suspense>
                }
              />
              <Route
                path="/products/communication"
                element={
                  <Suspense fallback={<></>}>
                    <CommunicationPage />
                  </Suspense>
                }
              />
              <Route
                path="/apps-payments"
                element={
                  <Suspense fallback={<></>}>
                    <IntegrationAndPaymentPage />
                  </Suspense>
                }
              />
              <Route
                path="/solutions"
                element={
                  <Suspense fallback={<></>}>
                    <SolutionPage />
                  </Suspense>
                }
              />
              <Route
                path="/security"
                element={
                  <Suspense fallback={<></>}>
                    <SecurityPage />
                  </Suspense>
                }
              />
              <Route
                path="/contact/get-in-touch"
                element={
                  <Suspense fallback={<></>}>
                    <GetInTouch />
                  </Suspense>
                }
              />
              <Route
                path="/about-us"
                element={
                  <Suspense fallback={<></>}>
                    <AboutUsPage />
                  </Suspense>
                }
              />
              <Route
                path="/legal"
                element={
                  <Suspense fallback={<></>}>
                    <LegalPage />
                  </Suspense>
                }
              />
              <Route
                path="/legal/terms-of-service"
                element={
                  <Suspense fallback={<></>}>
                    <TermesOfService />
                  </Suspense>
                }
              />
              <Route
                path="/legal/product-specific-terms"
                element={
                  <Suspense fallback={<></>}>
                    <SpecificProductService />
                  </Suspense>
                }
              />
              <Route
                path="/legal/dpa"
                element={
                  <Suspense fallback={<></>}>
                    <DataProcessing />
                  </Suspense>
                }
              />
              <Route
                path="/legal/acceptable-use"
                element={
                  <Suspense fallback={<></>}>
                    <AcceptableUsePolicy />
                  </Suspense>
                }
              />
              <Route
                path="/legal/developer-policy"
                element={
                  <Suspense fallback={<></>}>
                    <DeveloperPolicy />
                  </Suspense>
                }
              />
              <Route
                path="/legal/developer-terms"
                element={
                  <Suspense fallback={<></>}>
                    <DeveloperTerms />
                  </Suspense>
                }
              />
              <Route
                path="/legal/website-terms-of-use"
                element={
                  <Suspense fallback={<></>}>
                    <WebTermsOfUse />
                  </Suspense>
                }
              />
              <Route
                path="/legal/website-accessibility"
                element={
                  <Suspense fallback={<></>}>
                    <WebAccessibility />
                  </Suspense>
                }
              />
              <Route
                path="/legal/privacy-policy"
                element={
                  <Suspense fallback={<></>}>
                    <PrivacyPolicy />
                  </Suspense>
                }
              />
              <Route
                path="/legal/cookie-policy"
                element={
                  <Suspense fallback={<></>}>
                    <CookiePolicy />
                  </Suspense>
                }
              />
              <Route
                path="/legal/digital-services-act"
                element={
                  <Suspense fallback={<></>}>
                    <DigitalServicePolicy />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="/sign-in"
              element={
                <Suspense fallback={<></>}>
                  <SignInPage />
                </Suspense>
              }
            />
          </>
        )}

        <Route
          path="*"
          element={
            <Suspense fallback={<></>}>
              <PageNotFound />
            </Suspense>
          }
        />
        {/* <Route path="/register" element={<RegisterPage />} /> */}
      </Routes>
    </div>
  );
}

export default App;
