import React, { useState } from "react";
import {
  CopiedIcon,
  CopiedIcon2,
  CopyIcon,
  ScanIcon,
  ShareIcon,
} from "../../assets/Icons/profileIcon";
import { useWindowSize } from "../../utils/Hooks/useWindowSize";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { toasterObj } from "../Pricing/jsonData";

const ProfileCard = ({
  profileImg,
  name,
  position,
  setIsModalOpen,
  setIsModalExcOpen,
  url,
  file,
}) => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 720;
  const [copyStatus, setCopyStatus] = useState(false);

  const onCopyText = () => {
    setCopyStatus(true);
    setTimeout(() => setCopyStatus(false), 2000);
    toast(<CustomeMsg />, toasterObj);
  };

  const downloadVCF = (file) => {
    const vcfFileUrl = file;
    const link = document.createElement("a");
    link.href = vcfFileUrl;
    link.setAttribute("download", `${name}.vcf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the DOM
  };
  return (
    <div className="w-[580px] max_xl:w-[440px] bg-ButtonPHover p-6 max_sm:p-3 rounded-[30px] max_sm:rounded-[20px] h-max max_md:w-full flex flex-col max_md:flex-row max_sm:flex-col">
      <div>
        <div className="rounded-2xl max_md:w-[50%] max_sm:w-[45%]">
          <img
            src={profileImg}
            alt="Profile_image"
            className="rounded-2xl w-[100%] min-h-[500px] max_xl:min-h-[380px] max_md:min-h-[100px] max_sm:min-h-[100px]"
          />
        </div>
        <div className="mt-6 max_sm:mt-3">
          <h1 className="text-subHeadingW font-OutfitFont font-semibold text-white max_md:text-text3Xl max_sm:text-text2Xl">
            {name}
          </h1>
          <h4 className="text-textMedium font-OutfitFont font-normal text-white max_sm:text-textSmall">
            {position}
          </h4>
        </div>
      </div>
      <div className="mt-10 max_sm:mt-[20px] max_md:hidden max_sm:block">
        <div className="flex gap-2 items-center w-full">
          <div
            className="bg-ButtonP w-[370px] max_sm:w-[calc(100%-140px)] rounded-lg py-[11px] text-center text-white cursor-pointer"
            onClick={() => downloadVCF(file)}
          >
            Save contact
          </div>
          <div
            className="p-[11px] rounded-lg bg-ButtonP cursor-pointer max_sm:w-max"
            onClick={() => setIsModalExcOpen(true)}
          >
            <ShareIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
          <div className="p-[11px] rounded-lg bg-ButtonP cursor-pointer max_sm:w-max">
            <CopyToClipboard text={url} onCopy={onCopyText}>
              {copyStatus ? (
                <CopiedIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              ) : (
                <CopyIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div
            className="p-[11px] rounded-lg bg-ButtonP cursor-pointer max_sm:w-max"
            onClick={() => setIsModalOpen(true)}
          >
            <ScanIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
        </div>
      </div>
      <div className="hidden max_md:block max_sm:hidden">
        <div className="flex flex-col gap-3 items-end justify-end h-full">
          <div
            className="p-[11px] max_720:p-2 rounded-lg bg-ButtonP cursor-pointer max_md:w-max"
            onClick={() => setIsModalExcOpen(true)}
          >
            <ShareIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
          <div className="p-[11px] max_720:p-2 rounded-lg bg-ButtonP cursor-pointer max_md:w-max">
            <CopyToClipboard text={url} onCopy={onCopyText}>
              {copyStatus ? (
                <CopiedIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              ) : (
                <CopyIcon
                  style={{
                    width: isMobileView ? "18" : "24",
                    height: isMobileView ? "18" : "24",
                  }}
                />
              )}
            </CopyToClipboard>
          </div>
          <div
            className="p-[11px] max_720:p-2 rounded-lg bg-ButtonP cursor-pointer max_md:w-max"
            onClick={() => setIsModalOpen(true)}
          >
            <ScanIcon
              style={{
                width: isMobileView ? "18" : "24",
                height: isMobileView ? "18" : "24",
              }}
            />
          </div>
          <div
            className="bg-ButtonP w-[280px] max_720:w-[180px] rounded-lg py-[11px] text-center text-white cursor-pointer"
            onClick={() => downloadVCF(file)}
          >
            Save contact
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;

export const CustomeMsg = () => {
  const { width: windowWidth } = useWindowSize();
  const isMobileView = windowWidth <= 720;
  return (
    <div className="w-max flex gap-2 max_800:w-[200px]">
      <div className="max_800:mt-[2px]">
        <CopiedIcon2
          style={{
            width: isMobileView ? "18" : "24",
            height: isMobileView ? "18" : "24",
          }}
        />
      </div>
      <p className="text-textSmall font-normal text-[#23B339] font-OutfitFont max_sm:text-textExtraSmall">
        Copied to clipboard
      </p>
    </div>
  );
};
